import './css/splash.css';
import splash from './images/splash.jpg';

export default function Splash() {
  return (
    <div className="shader">
        <img src={splash} alt="Splash" />
        <div className="shader__layer specular">
          <div className="shader__layer mask"></div>
        </div>
      </div>
  )
}

import './css/app.css';
import Splash from './Splash';

function App() {
  return (
    <div className="app">
      <div className="app-container">
        <div className="app-header">
          <Splash />
        </div>
        </div>
      </div>
  );
}

export default App;